import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.user)?_c(VAlert,{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint.mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":"","icon":_vm.$vuetify.breakpoint.lgAndUp ? _vm.authIcon : null}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"grow",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? '12' : '0'}},[_vm._v(" Create a profile and you wouldn't need to enter your details every time you want to make an order. ")]),_c(VCol,{staticClass:"shrink",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? '12' : '0'}},[_c(VBtn,{attrs:{"block":"","exact":"","color":"secondary","to":{
                    name: _vm.$constants.ROUTE_NAMES.USER_PROFILE,
                }},on:{"click":_vm.registerClick}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(_vm._s(_vm.loginIcon))]),_vm._v(" Create Profile ")],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }