import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.shouldShow)?_c('div',[(_vm.$route.name === _vm.$constants.ROUTE_NAMES.CANALPLUS_INDEX)?_c(VAlert,{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint.mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":""}},[_c(VRow,[_c(VCol,[_vm._v(" We only support paying CANAL+ subscription for "),_c('b',[_vm._v("Cameroonian")]),_vm._v(" CANAL+ users at the moment. ")])],1)],1):(_vm.$route.name === _vm.$constants.ROUTE_NAMES.ORDERS_SHOW)?_c(VAlert,{staticClass:"v-alert--flat mb-4",attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint.mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"grow text-center"},[_vm._v(" ✨You can also pay your DStv subscription with ease via our WhatsApp phone number "),_c('br'),_c('a',{staticClass:"font-weight-bold text-decoration-none",attrs:{"href":"https://wa.me/237677777857?text=Hello","target":"_blank"}},[_vm._v(" 6 77 77 78 57 ")]),_c(VIcon,{attrs:{"small":"","color":"success"}},[_vm._v(_vm._s(_vm.mdiWhatsapp))])],1)],1)],1):(_vm.$route.name === _vm.$constants.ROUTE_NAMES.DSTV_INDEX)?_c(VAlert,{staticClass:"v-alert--flat mb-0",attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint.mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":""}},[_c(VRow,[(_vm.priceLockActive)?_c(VCol,[_vm._v(" DStv increased the price of DStv subscriptions from the 1"),_c('sup',[_vm._v("st")]),_vm._v(" of May 2024. You can activate the "),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                        name: _vm.$constants.ROUTE_NAMES
                            .FAQ_MAY_2024_PRICE_UPDATE,
                    }}},[_vm._v("price lock 🔐 feature")]),_vm._v(" and continue the paying the old price by ALWAYS renewing your subscription before it expires. "),_c('b',[_vm._v("Once your DStv subscription expires you will lose the price lock feature forever.")])],1):_c(VCol,[_vm._v(" DStv will increase the price of DStv subscriptions from the 1"),_c('sup',[_vm._v("st")]),_vm._v(" of May 2024. You can activate the "),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                        name: _vm.$constants.ROUTE_NAMES
                            .FAQ_MAY_2024_PRICE_UPDATE,
                    }}},[_vm._v("price lock 🔐 feature")]),_vm._v(" and continue the paying the old price by ALWAYS renewing your subscription before it expires. "),_c('b',[_vm._v("Once your DStv subscription expires you will lose the price lock feature forever.")])],1)],1)],1):(_vm.canInstallApp)?_c('install-alert'):(!_vm.user)?_c('auth-alert'):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }