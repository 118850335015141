



































import { Vue, Component } from "vue-property-decorator";
import { mdiDownloadOutline } from "@mdi/js";
import { Getter } from "vuex-class";
import InstallButton from "@/components/InstallButton.vue";
import { isAndroid } from "@/plugins/utils";
@Component({
    components: { InstallButton },
})
export default class AuthAlert extends Vue {
    installIcon: string = mdiDownloadOutline;
    @Getter("canInstallApp") canInstallApp!: boolean;

    get android(): boolean {
        return isAndroid();
    }
}
