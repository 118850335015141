






































import { Vue, Component } from "vue-property-decorator";
import { mdiLogin, mdiShieldLockOutline } from "@mdi/js";
import { Getter } from "vuex-class";
import { User } from "@/models/user";
import { addAnalyticsEvent } from "@/plugins/firebase";

@Component
export default class AuthAlert extends Vue {
    authIcon: string = mdiShieldLockOutline;
    loginIcon: string = mdiLogin;
    @Getter("user") user!: User | null;

    registerClick() {
        addAnalyticsEvent("auth_alert_button_clicked");
    }
}
