import Order, { ORDER_STATUS } from "@/models/order";
import naira from "@/filters/naira";
import currency from "@/filters/currency";

export interface SerializedOrderStatusChange {
    current_status: string;
    previous_status: string;
    changed_at: string;
}

export default class OrderStatusChange {
    private readonly _currentStatus: string;
    private readonly _previousStatus: string;
    private readonly _changedAt: Date;

    constructor(orderStatus: SerializedOrderStatusChange) {
        this._currentStatus = orderStatus.current_status;
        this._previousStatus = orderStatus.previous_status;
        this._changedAt = new Date(orderStatus.changed_at);
    }

    get id(): string {
        return this._currentStatus + this._currentStatus + this._changedAt;
    }
    get currentStatus(): string {
        return this._currentStatus;
    }

    isPlaced() {
        return this.currentStatus === ORDER_STATUS.PLACED;
    }

    htmlMessage(order: Order): string {
        if (this.isPlaced()) {
            return `<span class="text--secondary font-weight-bold">You</span> placed the order`;
        }

        if (
            this.currentStatus === ORDER_STATUS.PAYMENT_SUCCESSFULL &&
            !order.hasCryptoCurrencyPayment
        ) {
            return `<span class="text--secondary font-weight-bold">You</span> paid ${currency(
                order.paymentAmount,
                order.paymentCurrency
            )} using ${order.customerPaymentMethod}`;
        }

        if (
            this.currentStatus === ORDER_STATUS.PAYMENT_SUCCESSFULL &&
            order.hasCryptoCurrencyPayment
        ) {
            return `<span class="text--secondary font-weight-bold">You</span> paid ${
                order.paymentProviderAmount
            } ${order.paymentProviderName} (${currency(
                order.paymentAmount,
                order.paymentCurrency
            )})`;
        }

        if (
            (this.currentStatus === ORDER_STATUS.PAYMENT_CANCELLED ||
                this.currentStatus === ORDER_STATUS.PAYMENT_FAILED) &&
            order.isMobilePayment
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> cancelled the order because we did not receive the ${order.customerPaymentMethod} payment in time`;
        }

        if (
            (this.currentStatus === ORDER_STATUS.PAYMENT_CANCELLED ||
                this.currentStatus === ORDER_STATUS.PAYMENT_FAILED) &&
            order.hasBankCardPayment
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> could not charge ${currency(
                order.paymentAmount
            )} from your bank card`;
        }

        if (this.currentStatus === ORDER_STATUS.PAYMENT_REFUNDED) {
            return `<span class="text--secondary font-weight-bold">We</span> refunded your ${currency(
                order.paymentAmount
            )} payment`;
        }

        if (
            this.currentStatus === ORDER_STATUS.FULFILLMENT_FAILED &&
            order.isDstv
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> ran into an error while crediting your DSTV account`;
        }

        if (
            this.currentStatus === ORDER_STATUS.FULFILLMENT_FAILED &&
            order.isCanalplus
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> ran into an error while paying your ${order.itemWithoutPrice} subscription`;
        }

        if (
            this.currentStatus === ORDER_STATUS.FULFILLMENT_FAILED &&
            order.isAirtime
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> ran into an error while transferring your airtime`;
        }

        if (
            this.currentStatus === ORDER_STATUS.FULFILLMENT_FAILED &&
            order.isEneoPrepaid
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> ran into an error while buying ENEO credits`;
        }

        if (
            this.currentStatus === ORDER_STATUS.FULFILLMENT_FAILED &&
            order.isEneoPostpaid
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> ran into an error while paying your ENEO bill`;
        }

        if (
            this.currentStatus === ORDER_STATUS.FULFILLMENT_FAILED &&
            order.isCamwater
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> ran into an error while paying your camwater bill`;
        }

        if (
            this.currentStatus === ORDER_STATUS.FULFILLMENT_FAILED &&
            order.isCryptoSell
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> ran into an error while paying you ${currency(
                order.creditsAdded
            )}`;
        }

        if (
            this.currentStatus === ORDER_STATUS.FULFILLMENT_FAILED &&
            order.isExchange
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> ran into an error while paying ${currency(
                order.creditsAdded
            )} to <code>${order.recipientPhoneNumber}</code>`;
        }

        if (
            this.currentStatus === ORDER_STATUS.FULFILLMENT_SUCCESSFULL &&
            order.isDstv
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> credited your DSTV account with ${naira(
                order.creditsAdded
            )}`;
        }

        if (
            this.currentStatus === ORDER_STATUS.FULFILLMENT_SUCCESSFULL &&
            order.isCanalplus
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> paid your ${order.itemWithoutPrice} subscription.`;
        }

        if (
            this.currentStatus === ORDER_STATUS.FULFILLMENT_SUCCESSFULL &&
            order.isAirtime
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> transfered ${currency(
                order.creditsAdded
            )} of airtime to ${order.recipientPhoneNumber}`;
        }

        if (
            this.currentStatus === ORDER_STATUS.FULFILLMENT_SUCCESSFULL &&
            order.isEneoPrepaid
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> bought ${order.meterKiloWatts} of electiricty for ${order.billId}`;
        }

        if (
            this.currentStatus === ORDER_STATUS.FULFILLMENT_SUCCESSFULL &&
            order.isCamwater
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> paid your camwater bill with number ${order.billId}`;
        }

        if (
            this.currentStatus === ORDER_STATUS.FULFILLMENT_SUCCESSFULL &&
            order.isCryptoSell
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> transfered ${currency(
                order.creditsAdded
            )} to your phone number ${order.recipientPhoneNumber}`;
        }

        if (
            this.currentStatus === ORDER_STATUS.FULFILLMENT_SUCCESSFULL &&
            order.isExchange
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> transfered ${currency(
                order.creditsAdded
            )} to <code class="font-">${order.recipientPhoneNumber}</code>`;
        }

        if (
            this.currentStatus === ORDER_STATUS.FULFILLMENT_SUCCESSFULL &&
            order.isEneoPostpaid
        ) {
            return `<span class="text--secondary font-weight-bold">We</span> paid your electiricty bill with number ${order.billId} on ENEO`;
        }

        return "";
    }

    get statusColor(): string {
        const colors: Record<string, string> = {
            [ORDER_STATUS.PLACED]: "grey darken-3",
            [ORDER_STATUS.PAYMENT_FAILED]: "red darken-4",
            [ORDER_STATUS.PAYMENT_CANCELLED]: "red darken-4",
            [ORDER_STATUS.PAYMENT_SUCCESSFULL]: "orange darken-3",
            [ORDER_STATUS.PAYMENT_REFUNDED]: "green darken-3",
            [ORDER_STATUS.FULFILLMENT_FAILED]: "red darken-4",
            [ORDER_STATUS.FULFILLMENT_SUCCESSFULL]: "green darken-3",
        };
        return colors[this.currentStatus];
    }

    get previousStatus(): string {
        return this._previousStatus;
    }

    get changedAt(): Date {
        return this._changedAt;
    }
}
